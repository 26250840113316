import React from 'react';
import { useState, useContext, useEffect } from "react";
import { MyContext } from "../MainContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faFolderOpen, faGlobe, faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import PostModal from '../components/post';
import RefModal from '../components/reference';
import EditProfileModal from '../components/edit_profile';
//import LoadingScreen from '../components/loading_screen';
import Loading from '../components/loading';
import Footer from '../components/footer';
import { useNavigate } from "react-router-dom";
import Insight from '../components/insight';

import ShareModal from '../components/share_modal';

import LoadMore from '../components/load_more';

import DeleteModal from '../components/delete';
import SearchModule from '../components/search';
import Header from '../components/header_home';
import SkeletonPost from '../components/skeleton';

export default function HomePage () {
    
  const navigate = useNavigate();

    const {setUser, user, setCurrentInsight} = useContext(MyContext);

    const [openPostModal, setOpenPostModal] = useState(false);
    const [insights, setInsights] = useState(null)
    const [my_insights, setMyInsights] = useState(null)

    const [history, setHistory]  =  useState({
      likes: [],
      comments: [],
      followers: []
  });

    const [reference, setReference] = useState(null)
    const [profileModal, setProfileModal] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(false)
    const [has_More, setHasMore] = useState(true)

    const [is_insights, setIsInsights] = useState(2)
    const [is_my_insights, setIsMyInsights] = useState(2)
    const [is_history, setIsHistory] = useState(2)

    const [page, setPage] = useState(1)
    const [view, setView] = useState("")

    const [show_delete, setShowDelete] = useState(false)

    const [insight_id, setInsightID] = useState(0)

    const [is_edit, setIsEdit] = useState(false)
    const [post, setPost] = useState(false)


    const [is_delete, setIsDelete] = useState(false)

    const [del_msg, setDelMsg] = useState({})

    const [post_url, setPostUrl] = useState("")
    const [show_share_modal, setShareModal] = useState(false)

    const limit = 20;

  
    const refresh_user = async () => {

  
      if(!user.user_id){
  
          return
      }


        try{  
          
          const response = await fetch('https://choralty.com/api/schat/get_user_data.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', // Change content type to application/json
            },
            body: JSON.stringify({
    
              username: user.username,
              viewer: 0

            }),
          });
      
          const responseText = await response.json(); // Log the raw response text
          //console.log('Raw response:', responseText);
  
      
           const data = responseText //JSON.parse(responseText);
           if(data.success && data.success == true){
  
            
              setUser(data);

              //console.log('user dt up', data)
  
  
           }else{
  
            //
            
           }
  
        } catch (error) {
           
           console.error('Error sending request:', error);

        }
  
    
        
  };

    const handleStartPost = () => {

        setPost(false)
        setIsEdit(false)
        setOpenPostModal(true)

      };

    
      const show_share = (url) => {

         setPostUrl(url)

      };


      const handleSearch = () => {

        setPost(false)
        setIsEdit(false)
        navigate('/search')
      

      };


    const show_profile_edit = () => {
       
       setProfileModal(true)
    }

    const editPost = (post) => {

      //console.log('edit post', post)

      setIsEdit(false)

      setPost(post)
      setIsEdit(true)
      setOpenPostModal(true)

    };

      const closeModal = (modal = null) => {

        
      setPost(false)
      setIsEdit(false)

      setPostUrl("")
      setShareModal(false)

        setOpenPostModal(false)
        setProfileModal(false)

        setShowDelete(false)

        setInsightID(0)

      }


      const close_ref_modal = () => {
     
        setReference(null)
    }


    const go_to_website = (url) => {

      window.open(url, '_blank');


    }
    
  
    const refresh_history = async () => {

      //console.log('page', page, user.username)

      if(!user || !user.user_id || page < 1){
        return
      }
       
      try{  
        
        const response = await fetch('https://choralty.com/api/schat/get_history.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user.user_id,
          }),
        });
    
        const responseText = await response.json(); 
        //console.log('Raw response:', responseText);

    
         const data = responseText //JSON.parse(responseText);

         //console.log("history", data)

         if(data.success && data.success == true){

             //console.log('data l', data.result)

             setHistory(data.result)
             setIsHistory(10)

         }else{

             setIsHistory(0)
          
         }

      } catch (error) {
         console.error('Error sending request:', error);
         setIsHistory(1)
      }

      
};
 

    const refresh_insights = async () => {

      //console.log('page', page, user.username)

      if(!user || !user.user_id || page < 1){
        return
      }
       
      try{  
        
        const response = await fetch('https://choralty.com/api/schat/get_insights.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: user.user_id,
            page: page,
            limit: limit
          }),
        });
    
        const responseText = await response.json(); 
        //console.log('Raw response:', responseText);

    
         const data = responseText //JSON.parse(responseText);
         if(data.success && data.success == true){

               // console.log('data l', data.insights)

                if (data.insights && data.insights.length < limit) {
                       setHasMore(false);
                   }
            
             if(!insights){

                   setInsights(data.insights)
             }else{
                    
                    setInsights(prevInsights => {
                      // Create a set of existing insight IDs
                      const existingIds = new Set(prevInsights.map(insight => insight.id));
                      
                      // Filter out new insights that have IDs already in the existing set
                      const newInsights = data.insights.filter(insight => !existingIds.has(insight.id));
                      
                      // Concatenate filtered new insights with previous insights
                      return [...prevInsights, ...newInsights];
                  });
             }

             setIsInsights(10)

         }else{

             setHasMore(false);
             setIsInsights(0)
          
         }

      } catch (error) {
         console.error('Error sending request:', error);
         setIsInsights(1)
      }

      
};
 

      const refresh_my_insights = async (reset=false) => {

        setIsEdit(false)

        //console.log('fetching my insights page', page, user.username)

        if(!user || !user.user_id || page < 1){
          return
        }
         
        try{  
          
          const response = await fetch('https://choralty.com/api/schat/get_user_insights.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              username: user.username,
              user_id: user.user_id,
              page: page,
              limit: limit
            }),
          });
      
          const responseText = await response.json(); 
          //console.log('Raw response:', responseText);
  
      
           const data = responseText //JSON.parse(responseText);
           if(data.success && data.success == true){

                  //console.log('data l', data.insights.length)

                  if (data.insights && data.insights.length < limit) {
                         setHasMore(false);
                     }
              
               if(!my_insights || reset){
                     setMyInsights(data.insights)
               }else{
                      
                      setMyInsights(prevInsights => {
                        // Create a set of existing insight IDs
                        const existingIds = new Set(prevInsights.map(insight => insight.id));
                        
                        // Filter out new insights that have IDs already in the existing set
                        const newInsights = data.insights.filter(insight => !existingIds.has(insight.id));
                        
                        // Concatenate filtered new insights with previous insights
                        return [...prevInsights, ...newInsights];
                    });
               }

               setIsMyInsights(10)
  
           }else{
  
               setHasMore(false);
               setIsMyInsights(0)
            
           }
  
        } catch (error) {
           console.error('Error sending request:', error);
           setIsMyInsights(1)
        }
  
    
        
  };

    
  const load_more = (view) => {

    //console.log("lm", view)

    const scrollPosition = window.scrollY;  // Save the current scroll position
    setView(view)
    setScrollPosition(scrollPosition)
    setPage(prevPage => prevPage + 1);

  }

 
  const delete_insight = async (insight_id) => {

    if(is_delete){

        return

    }

      setIsDelete(true)

    try{  
      
      const response = await fetch('https://choralty.com/api/schat/delete_insight.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          insight_id: insight_id,
        }),
      });
  
      const responseText = await response.json(); 
      //console.log('Raw response:', responseText);

  
       const data = responseText //JSON.parse(responseText);

       setIsDelete(false)

       if(data.success && data.success == true){

           closeModal()
           setView('my_insight')

           if(page < 2){
              
               refresh_my_insights(true)
           }
           else{

            setPage(1)

           }
          

       }else{

          setDelMsg({"message": "Insight not deleted due to an error. please try again.", "mode": "danger"})
        
       }

    } catch (error) {

       setIsDelete(false)

       setDelMsg({"message": "Insight not deleted. please check your internet connection and retry.", "mode": "danger"})
        
       //console.error('Error sending request:', error);
       setIsInsights(1)
    }

    
};


const confirm_delete = (insight_id) => {
   
    setInsightID(insight_id)

    //console.log("cf del", insight_id)

}

useEffect(() => {

  if( post_url.length > 5 ){

      setShareModal(true)

  }

}, [post_url]); 


useEffect(() => {

  if( insight_id > 0 ){

      setShowDelete(true)

  }

}, [insight_id]); 


  
  useEffect(() => {

    //console.log("after del", view)

    if(view?.length > 3 && page > 0){

      if(view === "my_insight"){

            refresh_my_insights()
      }else{
         
        refresh_insights()
      }

    }

  }, [page]); 


  useEffect(() => {

    setCurrentInsight({})
    //console.log('init user', user)
     refresh_user()

  }, []); 

  useEffect(() => {

    if(scrollPosition){

        window.scrollTo(0, scrollPosition); 

    }

  }, [insights, my_insights]); 


      useEffect(() => {

        if(!openPostModal){

               refresh_my_insights()
        }
    
      }, [openPostModal]); 


      useEffect(() => {

        refresh_my_insights()
        refresh_insights()
        refresh_history()
    
      }, [user]); 


      const refresh_all = () => {
     
        refresh_my_insights()
        refresh_insights()
        refresh_history()
    }
    


      const toggle_like = async (insight_id, type) => {

        //console.log("tg like", insight_id, type)

        if(!user || !user.user_id){
          return
        }
         
        try{  
    
          const response = await fetch('https://choralty.com/api/schat/toggle_like.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user_id: user.user_id,
              insight_id : insight_id
            }),
          });
      
          const responseText = await response.json(); 
          //console.log('Raw response:', responseText);
    
    
           const data = responseText //JSON.parse(responseText);
           if(data.success && data.success == true ){

                
                let newTotalLikes = 0;
                let newUserLiked = 0;

                if(type === "following"){


                const insight = insights.find((insight) => insight.id === insight_id);

                  if (insight) {


                    if(data.like){

                      newTotalLikes = parseInt(insight.total_likes, 10) + 1;
                      newUserLiked = 1;

                    }else{

                      newTotalLikes = parseInt(insight.total_likes, 10) - 1;
                      newUserLiked = 0;

                    }

                  setInsights((prevInsights) =>
                    prevInsights.map((insight) =>
                      insight.id === insight_id
                        ? { ...insight, total_likes: newTotalLikes, user_liked: newUserLiked }
                        : insight
                    )
                  );
                };

                }else{ //my insights


                  const my_insight = my_insights.find((insight) => insight.id === insight_id);

                    if (my_insight) {


                      if(data.like){

                        newTotalLikes = parseInt(my_insight.total_likes, 10) + 1;
                        newUserLiked = 1;

                      }else{

                        newTotalLikes = parseInt(my_insight.total_likes, 10) - 1;
                        newUserLiked = 0;

                      }

                    setMyInsights((prevInsights) =>
                      prevInsights.map((insight) =>
                        insight.id === insight_id
                          ? { ...insight, total_likes: newTotalLikes, user_liked: newUserLiked }
                          : insight
                      )
                    );
                  };

                }
                
                  
           }else{
    
              //
           }
    
        } catch (error) {
           console.error('Error sending request:', error);
    
           //setUserData(404)
        }
    
    
        
    };
    

  return (
   
    <div className="container">

       <Header />
          
            {!user.user_id ? (

                <Loading />
                
              ) : (

                  <div className="container-fluid mt-5 pt-3">
                    <div className="row">
                      <div className="col-md-8 col-sm-12 border p-3">

                      <div className="profile-container">
                      <div className="profile-info d-flex justify-content-between align-items-start">
  {/* Profile Image and Edit Button */}
  <div className="d-flex align-items-center">
    <img
      src={user.photo || `${process.env.PUBLIC_URL}/avatar.jpg`}
      alt="Profile Image"
      className="profile-image"
      style={{ objectFit: "cover", marginRight: "10px" }}
    />
    <button className="edit-profile-btn m-3" onClick={show_profile_edit}>
      Edit Profile
    </button>
  </div>

  {/* Profile Details */}
  <div className="profile-details mt-4">
    <div className="profile-fullname" style={{  maxWidth: '100%' }}>
    {user.name}
  </div>
    <div className="profile-name">@{user.username}</div>
  </div>
</div>


                <div className="profile-bio">
                    <p>{user.bio}</p>
                    {user.website?.length > 4 &&  <span className="web-btn" onClick={() => go_to_website(user.website)}><FontAwesomeIcon icon={faGlobeAfrica} /> website</span>}
                </div>
                <div className="profile-stats">
                    <div className="followers">{user.followers} Follower{parseInt(user.followers) > 1 ? 's' : ''}</div>
                    <div className="following">{user.following} Following</div>
                </div>
            </div>

            <div className="floating-button" onClick={handleStartPost}>
            <i className="fa-solid fa-pen-nib"></i>
                </div>

                {user && user.user_id && <PostModal show = {openPostModal} close ={closeModal}  user_id={user.user_id}  refresh_my_insights={refresh_my_insights} is_edit = {is_edit} post={post} />}

                {user && user.user_id && <EditProfileModal show = {profileModal} close ={closeModal}  user={user} setUser={setUser} />}
                        
            <Tabs defaultActiveKey="for-you" id="myTabs" className="justify-content-between">

                  <Tab eventKey="for-you" title="For You">

                    <div className='mt-4'>
                    { (insights !== null && is_insights > 2)  ? (

                      insights.map(insight => (

                        <Insight key={insight.id} share ={show_share} insight={insight} setReference={setReference}  toggle_like={toggle_like} type={'following'}  setInsight={setCurrentInsight} />
                      ))

                    ): (


                      (is_insights === 2)  ? (

                        <SkeletonPost />
  
                      ): (

                         
                          (is_insights < 1)  ? (

                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
                                <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
                                <h5>This space will display insights from those you follow.</h5>
                              
                                <button className="btn btn-primary b-primary mt-2" onClick={handleSearch}>
                                  <b>Let's get started!</b>
                                </button>
                              </div>
      
                          ): (
                      
                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
                            <h5>An error occured. Please check your Internet connection & retry.</h5>
                          
                            <button className="btn btn-primary b-primary mt-2" onClick={refresh_all}>
                              <b>Retry</b>
                            </button>
                          </div>
            
                          )
                        
                  
                     
         
                      )
                        
                
       
                    )
                              
                    }

                    
          {insights !== null && has_More &&  
                  
                  <LoadMore view="insight" func={() => load_more("insight")}   text="Load more Insights" /> 
                  
                  }

        <div style={{marginBottom: '90px'}} />
                    </div>
                  </Tab>

                  <Tab eventKey="trending" title="My Insights">
                  
                    <div className='mt-4'>

                      { (my_insights !== null && is_my_insights > 0) ? (

                        my_insights.map(insight => (

                          <Insight key={insight.id} share ={show_share} insight={insight} setReference={setReference}  toggle_like={toggle_like} type={'mine'}  setInsight={setCurrentInsight} delete_insight={confirm_delete} edit_insight={editPost} />

                        ))

                      ): (

                        (is_my_insights === 2)  ? (

                          <SkeletonPost />
    
                        ): (
  
                        
                                (is_my_insights < 1)  ? (

                                  <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
                                  <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
                                  <h5>You have not published any insights yet.</h5>
                                
                                  <button className="btn btn-primary b-primary mt-2" onClick={handleStartPost}>
                                    <b>Start Here</b>
                                  </button>
                                </div>
          
                              ): (
                          
                                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
                                <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
                                <h5>An error occured. Please check your internet connection & retry.</h5>
                              
                                <button className="btn btn-primary b-primary mt-2" onClick={refresh_my_insights}>
                                  <b>Retry</b>
                                </button>
                              </div>
                
                
                              )

                    )
                       
       
                    )
                                
                      }

                      {my_insights !== null && has_More &&    <LoadMore view="my_insight" func={() => load_more("my_insight")}   text="Load more Insights" />  }

        <div style={{marginBottom: '90px'}} />

      

                      </div>
                  </Tab>
                  <Tab eventKey="saved" title="Activities">
                    
                    <div className='mt-4'>

                       
                            {history.likes && history.likes
                             .filter(like => like.username !== user.username)
                             .map((like, index) => (
                              <div key={index} className='history-div border-bottom'>
                                  <b onClick={() => navigate(`/${like.username}`)} className="history-href" >{like.first_name} {like.last_name}</b> <FontAwesomeIcon style={{color: '#48887b'}} icon={faHeart} /> <b onClick={() => navigate(`/${user.username}/insight/${like.insight_id}`)} className="history-href">your post</b>
                              </div>
                            ))}

                            {history.comments && history.comments
                             .filter(comment => comment.username !== user.username)
                            .map((comment, index) => (
                              <div key={index} className='history-div border-bottom'>
                                  <b onClick={() => navigate(`/${comment.username}`)} className="history-href" >{comment.first_name} {comment.last_name}</b> commented on <b onClick={() => navigate(`/${user.username}/insight/${comment.insight_id}`)} className="history-href">your post</b>
                              </div>
                            ))}

                            {history.followers && history.followers
                             .filter(follower => follower.username !== user.username)
                            .map((follower, index) => (

                              <div key={index} className='history-div border-bottom'>
                                  <b onClick={() => navigate(`/${follower.username}`)} className="history-href" >{follower.first_name} {follower.last_name}</b> follows you
                              </div>
                            ))}


                     { is_history < 1 &&

                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
                        <h5>Engagements with your insight will be shown here.</h5>
                     
                      </div> }



                      { is_history === 1 && <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh', textAlign: 'center' }}>
                      <FontAwesomeIcon icon={faFolderOpen} size="4x" className="mb-3 text-dark" />
                      <h4>An error occured. Please check your internet connection & retry.</h4>

                      <button className="btn btn-primary b-primary" onClick={refresh_all}>
                        <b>Retry</b>
                      </button>
                    </div>}


                    </div>

                    <div style={{marginBottom: '90px'}} />

                  </Tab>
                </Tabs>

                      </div>
                      <div className="col-md-4 d-none d-md-block border p-3">
                        <SearchModule />

                      </div>
                    </div>
                  </div>

              )}

      <Footer page="home" />
      {reference !== null && <RefModal data={reference}  close ={close_ref_modal} />}

      {show_share_modal && <ShareModal post_url={post_url} close ={closeModal} />}

      {show_delete  && <DeleteModal text={'Are you sure you want to delete this insight? \n this action is not reversible.'} close = {closeModal} delete_fn={delete_insight} id={insight_id} del_msg = {del_msg} />}

    </div>

  );
};

