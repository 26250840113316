import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCamera, faCheckCircle, faTrashAlt, faWarning } from '@fortawesome/free-solid-svg-icons';

import { imageFileResizer } from "@peacechen/react-image-file-resizer";
import { validateURL } from '../utils/functions';

import { Cropper, getCroppedImg } from "react-cropper-custom";

import "react-cropper-custom/dist/index.css";


 
export default function EditProfileModal({ close, show, user, setUser }) {
 
    const [first, last] = user.name.split(' ');

    const [username, setUsername] = useState(user.username || "");
    const [firstName, setFirstName] = useState(first || "");
    const [lastName, setLastName] = useState(last || "");
    const [bio, setBio] = useState(user.bio? user.bio : "");
    const [location, setLocation] = useState(user.location || "");
    const [website, setWebsite] = useState(user.website || "");
    const [birthdate, setBirthdate] = useState(user.dob || new Date());
    const [profileImage, setProfileImage] = useState(user.photo || `${process.env.PUBLIC_URL}/avatar.jpg`);


    const [img, setImg] = useState(false);

    const [croppedImg, setCroppedImg] = useState(false);

    const [zoom, setZoom] = useState(1);

    const [aspect, setAspect] = useState(1);


    const fileInputRef = useRef(null);

    const [is_updating, setISUpating] = useState(false)

    const [username_pass, setUsernamePass] = useState(true)

    const [username_msg, setUsernameMsg] = useState({})

    const [website_msg, setWebsiteMsg] = useState({})

    const [msg, setMsg] = useState(null);


    const onCropComplete = async (croppedArea) => {
      try {
        const canvasSize = {
          width: 300,
          height: 300 * aspect
        };

        const image = await getCroppedImg(img, croppedArea, canvasSize);

         setProfileImage(image)

      } catch (e) {
        console.error(e);
      }
    };
  
    const closeCropModal = () => {
         
        setImg(false)

        uploadPhoto()

    }

    const photoChange = async (e) => {

      const file = e.target.files[0];

      console.log("pt ch", file)


      if (file) {
        try {
  
          const uri = await imageFileResizer({
              compressFormat: "jpeg",
              file: file,
              maxHeight: 300,
              maxWidth: 300,
              minHeight: 200,
              minWidth: 200,
              outputType: "base64",
              quality: 70,
              rotation: 0,
            });

            setImg(uri)

      } catch (error) {
        console.log('Error compressing image:', error);
      }
    
    }  else{

      console.log("no file", file)
      

    }

  }

    const pass_username = async(query) => {

      if(query.length < 3){
         return
      }

    try{  

      const response = await fetch('https://choralty.com/api/schat/pass_username.php', {
        method: 'POST',
        headers: {

          'Content-Type': 'application/json', // Change content type to application/json
        },
        body: JSON.stringify({
          username:query
        }),
      });
  
      const responseText = await response.json(); // Log the raw response text

      console.log('Raw search response:', responseText);

       const data = responseText //JSON.parse(responseText);

       if(data.success && data.success == true){

          setUsernamePass(false);

          setUsernameMsg({"msg" : `${data.username} is not available`, "type" : "danger"});

       }else{

        setUsernamePass(true);

        setUsernameMsg({"msg" : `${data.username} is available`, "type" : "success"});

       }

    } catch (error) {
       
       setUsernamePass(false);

       setUsernameMsg({"msg" : `Error validating ${username}. Please check your internet connection and retry`, "type" : "danger"});

       console.error('Error sending request:', error);
    }

  }



  const handleBio = async (e) => {

    const value = e.target.value;

    if(value.length > 350){

         return
    }

    setBio(value);


  }


  const handleLocation = async (e) => {

    const value = e.target.value;

    if(value.length > 70){

         return
    }

    setLocation(value);


  }

    const checkUsername = async (e) => {

      setUsernameMsg({});

      const value = e.target.value;

      const regex = /^[a-zA-Z0-9._-]*$/; // Regular expression to allow only letters, ., _, and -
      

      if(value === user.username){

           setUsername(value);

           return
      }

     else if (!regex.test(value)) {

        setUsernameMsg({"msg" : `Invalid character (${value.replace(username, "")}) entered. use only [ a-z, 0-9 _ - . ]`, "type": "danger"});

        return

      }else if (value.length < 3 || value.length > 20) {

        setUsernameMsg({"msg" : "Profile ID should be between 3 to 20 chars", "type": "danger"});

      }

       setUsername(value);

       pass_username(value)

  };


  const handleWebsite = async (e) => {

    setWebsiteMsg({});

    const value = e.target.value;

    const is_url = validateURL(value)
    

   if (!is_url) {

      setWebsiteMsg({"msg" : `Invalid URL`, "type": "danger"});

    }

    if(value > 300){

      setWebsiteMsg({"msg" : `URL should be less than 300 chars`, "type": "danger"});

      return

    }

    setWebsite(value);

};


  const handleSubmit = async (e) => {

    setMsg(null)

    e.preventDefault();

    if(is_updating){

        return
    }

    if(firstName.length < 3 || lastName.length < 2 || username.length < 3 || firstName.length > 70 || lastName.length > 70 || !username_pass || location.length > 70 ){

        setMsg({'msg': 'Please review form for errors and retry.', 'mode': 'danger'})
        return
    }

    setISUpating(true)
     
      try{  
        
        const response = await fetch('https://choralty.com/api/schat/update_profile.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Change content type to application/json
          },
          body: JSON.stringify({
            bio: bio,
            username: username,
            location: location,
            first_name: firstName,
            last_name: lastName,
            website: website,
            dob: birthdate,

            user_id: user.user_id
          }),
        });
    
        const responseText = await response.json(); // Log the raw response text
        console.log('Raw response:', responseText);

    
         const data = responseText //JSON.parse(responseText);
         if(data.success && data.success == true){

          setISUpating(false)

          setMsg({'msg': 'Profile updated', 'mode': 'success'})
          
          setUser((prevUser) => ({
            ...prevUser,
            username: username,
            bio: bio,
            location: location,
            name: firstName+' '+lastName,
            website: website,
            dob: birthdate

          }));

          close()


         }else{

          setMsg({'msg': data.messages , 'mode': 'danger'})

          setISUpating(false)
          
         }

      } catch (error) {
         
         setMsg({'msg': 'Error updating profile. Please check your internet and retry.' , 'mode': 'danger'})
         console.error('Error sending request:', error);
         setISUpating(false)
      }

  
      
};

const handleFileChange_bk = async (e) => {

    const file = e.target.files[0];
    if (file) {
      try {

        const uri = await imageFileResizer({
            compressFormat: "jpeg",
            file: file,
            maxHeight: 300,
            maxWidth: 300,
            minHeight: 200,
            minWidth: 200,
            outputType: "base64",
            quality: 70,
            rotation: 0,
          });
      
        const response = await fetch('https://choralty.com/api/schat/update_profile_photo.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
              photo:uri,
              user_id: user.user_id
            }),
          });

        if (response.ok) {

          const data = await response.json();

          //console.log(data)

          if(data.success && data.success == true){

          setProfileImage(data.photo);

          setUser((prevUser) => ({
            ...prevUser,
            photo: data.photo,

          }));

          console.log('Image uploaded successfully!');

          }else{
              
            setMsg({ mode: 'danger', msg: 'Image upload failed!' });

          }

        } else {

          setMsg({ mode: 'danger', msg: 'Image upload failed!' });

        }

      } catch (error) {
        console.error('Error uploading the image:', error);
        setMsg({ mode: 'danger', msg: 'Image upload failed!' });
      }
    }
  };


  const uploadPhoto = async () => {

    if (profileImage) {

      console.log("start upload")

      try {

        const response = await fetch('https://choralty.com/api/schat/update_profile_photo.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
              photo:profileImage,
              user_id: user.user_id
            }),
          });

        if (response.ok) {

          const data = await response.json();

          //console.log(data)

          if(data.success && data.success == true){

          setProfileImage(data.photo);

          setUser((prevUser) => ({
            ...prevUser,
            photo: data.photo,

          }));

          console.log('Image uploaded successfully!');

          }else{
              
            setMsg({ mode: 'danger', msg: 'Image upload failed!' });

          }

        } else {

          setMsg({ mode: 'danger', msg: 'Image upload failed!' });

        }

      } catch (error) {
        console.error('Error uploading the image:', error);
        setMsg({ mode: 'danger', msg: 'Image upload failed!' });
      }
    }
  };



const handleIconClick = () => {

  console.log("fl clirked")
    fileInputRef.current.click();
  };



  return (
    <div
    className={`modal ${show ? ' show' : ''}`}
    tabIndex="-1" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Profile</h5>
            <button type="button" className="btn-close" onClick={close}></button>
          </div>

          <div className="modal-body">
            <form onSubmit={handleSubmit}>

                
            <div className="mb-3 p-2 text-center">
               
                   {!img &&  <div className="position-relative d-inline-block"  onClick={handleIconClick}>

                    <img
                        src={profileImage}
                        alt="Profile"
                        className="rounded-circle"
                        style={{ width: "150px", height: "150px", objectFit: "cover" }}
                    />
                    <div
                        className="position-absolute top-50 start-50 translate-middle"
                        style={{
                        width: "70px",
                        height: "70px",
                        backgroundColor: "#000000b3",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon icon={faCamera} style={{color:'white', fontSize:'35px', opacity:'0.7'}} />

                    </div>
                    </div> }


                    {img && <div className={`modal show`} tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>

                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                           
                            <p style={{padding:14, margin:0, fontWeight:800}}>To crop the photo, zoom in or out and drag to adjust.</p>

                            <div className="modal-body">
                                
                              <div className="mb-3 text-center">
                                
                              
                                    <div className="wrapper">
                                        <Cropper
                                          src={img}
                                          zoom={zoom}
                                          aspect={aspect}
                                          onZoomChange={setZoom}
                                          onCropComplete={onCropComplete}
                                        />
                                      </div>
                  
                                  <button type="close" className="mt-3 btn w-100 btn-primary b-primary" onClick={closeCropModal}>close</button>
                                

                            </div>
                          </div>
                        </div>
                      </div>
                     </div>}


                    <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={photoChange}
                        />

                </div>
                  
                <div className="mb-3 p-2 border rounded-3 ep-form">

                        <div className='row'>

                                    <div className='col-6'>
                                        <label htmlFor="firstName" className="form-label">First Name <span style={{marginLeft:7}} className={`text-${(firstName.length > 70 || firstName.length < 3) ? 'danger' : 'success'}`}>{`${firstName.length} / 70`}</span></label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                        />
                                        </div>

                                        <div className='col-6'>
                                        <label htmlFor="lastName" className="form-label">Last Name <span style={{marginLeft:7}} className={`text-${(lastName.length > 70 || lastName.length < 3) ? 'danger' : 'success'}`}>{`${lastName.length} / 70`}</span></label>
                                        <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                        />
                                        </div>
                            </div>
                </div>
             
                <div className="mb-3 p-2 border rounded-3 ep-form">
                    <label htmlFor="bio" className="form-label">Bio <span style={{marginLeft:7}} className={`text-${(bio?.length > 350 || bio?.length < 10) ? 'danger' : 'success'}`}>{`${bio?.length} / 350`}</span></label>
                    <textarea
                    className="form-control"
                    id="bio"
                    value={bio}
                    onChange={handleBio}
                    required
                    />
                </div>
                <div className="mb-3 p-2 border rounded-3 ep-form">
                    <label htmlFor="username" className="form-label">Profile ID {username_msg && username_msg.type && <span style={{marginLeft:7}} className={`text-${username_msg.type}`}> <FontAwesomeIcon icon={username_msg.type === "success" ? faCheckCircle : faWarning}/> {username_msg.msg}</span>}</label>
                    <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={checkUsername}
                    required
                    />
                </div>

                <div className="mb-3 p-2 border rounded-3 ep-form">
                    <label htmlFor="location" className="form-label">Location <span style={{marginLeft:7}} className={`text-${(location.length > 70 || location.length < 3) ? 'danger' : 'success'}`}>{`${location.length} / 70`}</span></label>
                    <input
                    type="text"
                    className="form-control"
                    id="location"
                    value={location}
                    onChange={handleLocation}
                    required
                    />
                </div>
                <div className="mb-3 p-2 border rounded-3 ep-form">

                    <label htmlFor="website" className="form-label">Website (optional) {website_msg && website_msg.type && <span style={{marginLeft:7}} className={`text-${website_msg.type}`}> <FontAwesomeIcon icon={website_msg.type === "success" ? faCheckCircle : faWarning}/> {website_msg.msg}</span>}</label>

                    <input
                    type="url"
                    className="form-control"
                    id="website"
                    value={website}
                    onChange={handleWebsite}
             
                    />
                </div>
                <div className="mb-3 p-2 border rounded-3 ep-form">
                    <label htmlFor="birthdate" className="form-label">Birthdate (optional)</label>
                    <input
                    type="date"
                    className="form-control"
                    id="birthdate"
                    value={birthdate}
                    onChange={(e) => setBirthdate(e.target.value)}
                  
                    />
                </div>
              
                {msg && <span className={`text-${msg.mode}`}><FontAwesomeIcon icon={msg.mode === "success" ? faCheckCircle : faWarning}/> {msg.msg}</span>}
                <button type="submit" className="mt-3 btn w-100 btn-primary b-primary">{`${!is_updating ? 'Update Profile' : 'Updating Profile...'}`}</button>
                </form>

          </div>
        </div>
      </div>
    </div>
  );
}

