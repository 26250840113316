 // LoadingScreen.js
 import React  from 'react';

 import { useState, useContext, useEffect } from "react";
import { MyContext } from "../MainContext";
  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faBell, faUser, faBook, faComment, faHeart, faShare, faPowerOff } from '@fortawesome/free-solid-svg-icons';

 
 const Header = () => {

    const {setUser, user, exit_user} = useContext(MyContext);
 
 
     return (
    <>
          {/* Top Navigation */}
       <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
       <div className="container">
            <a className="navbar-brand" href="#"><img src={`${process.env.PUBLIC_URL}/logo_wordmark_color.svg`} alt="Placeholder Image" className="img-fluid hm-logo" style={{maxWidth:'150px'}} />
            </a>


                <div className="ml-auto">
                <FontAwesomeIcon icon={faPowerOff} onClick={exit_user} /> 
                </div>
            </div>
            </nav>
 
        </>
        
         
     );
 };
 
 export default Header;
 