 // LoadingScreen.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faCog, faQuestion, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import SettingsModal from './settings_modal';

const Footer = ({page}) => {


const [show_settings, setShowSettings] = useState(false)

 const toggle_settings = () => {
          
      setShowSettings(!show_settings)

 }


 const close_setting = () => {
          
  setShowSettings(false)

}




  const navigate = useNavigate();

    return (
   <>
        <nav className="navbar navbar-light bg-light fixed-bottom d-block d-md-none">
        <div className="nav justify-content-around w-100">
          <a className={`nav-link ${page === "home" ? 'nav-link-selected' : ''}`} onClick={() =>  navigate(`/`)} >
            <FontAwesomeIcon className="menu-icon" icon={faHome}  />
          </a>
          <a className={`nav-link ${page === "search" ? 'nav-link-selected' : ''}`}  onClick={() =>  navigate(`/search`)}>
            <FontAwesomeIcon className="menu-icon" icon={faSearch} />
          </a>
          <a className={`nav-link ${page === "about" ? 'nav-link-selected' : ''}`}  onClick={() =>  navigate(`/about`)}>
            <FontAwesomeIcon className="menu-icon" icon={faQuestionCircle} />
          </a>
          <a className={`nav-link ${show_settings ? 'nav-link-selected' : ''}`} onClick={toggle_settings}>
            <FontAwesomeIcon className="menu-icon" icon={faCog} />
          </a>
        </div>
       </nav>

        {show_settings && <SettingsModal  close={close_setting} />}
       </>
       
        
    );
};

export default Footer;
