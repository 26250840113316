import React, {useState,  useEffect} from 'react'
export const MyContext = React.createContext();

export default function MainContext({children}) {
  
  const [user, setUser] = useState({})
  const [userData, setUserData] = useState({})
  const [current_insight, setCurrentInsight] = useState({})

  const storage = {

    setItem: (key, value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getItem: (key) => {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    },
    removeItem: (key) => {
      localStorage.removeItem(key);
    },
    clear: () => {
      //localStorage.clear();
      localStorage.removeItem("sc_chat");
    }
  };


  useEffect(() => {

    const user_ = storage.getItem('sc_chat');
  
    if(user_ && user_.user_id){
  
      console.log('user stance found')
  
      setUser(user_)
  
    }
   
  }, []);



 useEffect(() => {


    if(user && user.user_id){

      storage.setItem('sc_chat', user);

      console.log('user data updated', user.username)


    }else{
         
     //

    }

 }, [user]);




const exit_user = () => {

  storage.clear() 
  setUser({}) 
}


  const defaultValue = {
   
    user, setUser,
    userData, setUserData,
    exit_user,
    current_insight, setCurrentInsight

  }

  return (
   <MyContext.Provider value={defaultValue}>
     {children}
   </MyContext.Provider>
  )
}
