// LoadingScreen.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import './LoadingScreen.css';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            {3 < 2 && <FontAwesomeIcon icon={faSpinner} spin size="3x"  color='#48887b' />}

                    <img src={`${process.env.PUBLIC_URL}/logo_wordmark_color.svg`} alt="Placeholder Image" className="img-fluid hm-logo" style={{ maxWidth: '150px' }} />
                  
        </div>
    );
};

export default LoadingScreen;
