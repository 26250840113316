import React from 'react';
import { useState, useContext, useEffect } from "react";
import { MyContext } from "../MainContext";

import RefModal from '../components/reference';

import Loading from '../components/loading';
import Footer from '../components/footer';


import Header from '../components/header';
import { useParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch,  faWarning, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import Insight from '../components/insight';
import LoadMore from '../components/load_more';


export default function SearchPage () {

    const { query } = useParams();
    const navigate = useNavigate();

    const {user, setCurrentInsight} = useContext(MyContext);

           const [input, setInput] = useState('');
           const [results, setResults] = useState([]);
           const [type, setType] = useState('insight');
           const [msg, setMsg] = useState('');
       
           const [reference, setReference] = useState(null)
       
           const [has_More, setHasMore] = useState(true)
           const [page, setPage] = useState(1)
       
           const [scrollPosition, setScrollPosition] = useState(false)
           
       
           const limit = 20;

           const close_ref_modal = () => {
     
            setReference(null)
        }
        


           useEffect(() => {

            if(query){
               
                setInput(query)
            }

            if(!user.user_id){

                navigate('/')
            }
          
           }, [])
    
           
           const load_more = () => {
       
              // console.log('load more pg; ', page)
       
               const scrollPosition = window.scrollY;  // Save the current scroll position
               setScrollPosition(scrollPosition)
               setPage(prevPage => prevPage + 1);
           
             }
       
       
             const toggle_like =  () => {
       
               //
              //console.log('like from search clicked')
         
               
           };
           
       
       
            // console.log('url q', query)
       
             useEffect(() => {
       
               if(query){
           
                   setInput(query)
           
               }
           
             }, []); 
       
       
             useEffect(() => {

                //console.log(results)
       
               if(scrollPosition){
           
                   window.scrollTo(0, scrollPosition); 
           
               }
           
             }, [results]); 
       
               
         useEffect(() => {
       
           if(page > 0 && input?.length > 1){
       
               fetch_result(input)
       
           }
       
         }, [page, input]); 
           
       
           const handleChange = async (e) => {
               const query = e.target.value;
               setInput(query);
       
               setMsg({})

               
               navigate(`/search/${query}`);
               
       
               if(query?.length < 2){
       
                   setPage(0)
                   setResults([])
       
                   return
               }
                
                setPage(0)
                fetch_result(query)
       
           };
       
       
           const fetch_result = async(query) => {
       
               let page_ = page > 0 ? page : 1;
       
                    
             try{  
       
               const response = await fetch('https://choralty.com/api/schat/search.php', {
                 method: 'POST',
                 headers: {
       
                   'Content-Type': 'application/json', // Change content type to application/json
                 },
                 body: JSON.stringify({
                   keyword:query,
                   limit:limit,
                   page: page_
                 }),
               });
           
               const responseText = await response.json(); // Log the raw response text
               //console.log('Raw search response:', responseText);
       
           
                const data = responseText //JSON.parse(responseText);

                //console.log("sc re", data)
       
                if(data.success && data.success === true){
       
                   setType(data.type);
       
                   setResults(data.result)

                   //console.log("resy", data.result)
       
                   setMsg({'msg': `${data.result[0].total_count} result${data.result[0].total_count > 1 ? "s" : ""} found for ${query}`, 'mode': 'success'})
       
                   //console.log(data.result[0].total_count, results?.length)
       
       
                       
                       if ( (page * limit + data.result?.length > data.result[0].total_count )|| data.result?.length < limit) {
       
                           setHasMore(false);
       
                       }else{
                            
                           setHasMore(true);
       
                       }
               
                       if(results?.length < 1){
       
                           setResults(data.result);
       
                           //console.log('reseting')
       
                       }else{
       
                           //console.log('populating')
                               
                               setResults(prevRes => {
                               
                               const existingIds = new Set(prevRes.map(res => res.id));
                               
                              
                               const newRes = data.result.filter(res => !existingIds.has(res.id));
                               
                           
                               return [...prevRes, ...newRes];
                           });
                       }
       
       
                }else{
       
                 setMsg({'msg': `0 result found for ${query}`, 'mode': 'danger'})
       
                 setResults([]);
       
                // setHasMore(false)
       
                 
                }
       
             } catch (error) {
                
               setResults([]);
       
               setMsg({'msg': 'Error running search. Please check your internet connection and retry.' , 'mode': 'danger'})
       
                console.error('Error sending request:', error);
             }
       
           }
       


  return (
   
    <div className="container">

       <Header />
          
            {!user.user_id ? (

                <Loading />
                
              ) : (

                  <div className="container-fluid mt-5 pt-3">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 border p-3">

                             
    {/*search module*/}

        <div className="search-component">
            <div className="search-box">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                    id="search2"
                    type="text"
                    value={input}
                    onChange={handleChange}
                    placeholder="Search..."
                />
            </div>

            {msg?.msg && <span style={{fontSize:'smaller'}} className={`text-${msg.mode}`}>{msg.mode === "danger" ?( <FontAwesomeIcon icon={faWarning} />) : (<FontAwesomeIcon icon={faCheckCircle} />)} {msg.msg}</span>}

            {results?.length < 1 && (
                <div className="results-help text-justify my-4 refs-div" style={{fontSize:'smaller'}}>
                    <h6><b>How to Use Search</b></h6>
                    <hr/>
                    <p>

                    <b>Searching For a User via Profile ID</b><br/>Start search with "@" e.g @pastor_sam<br/><br/>
                        
                        <b>Search for a Bible Verse</b><br/>e.g Esther 1:1, Genesis 3:4-5<br/><br/>
                  
                    <b>Searching a keyword</b><br/>Enter keyword e.g overcometh</p>
                </div>
            )}

            {results && (

                <div className="results">
                    <p className='d-none'>{results.length} result{`${results.length> 1 ? "s": ""}`} found</p>
                   
                   <div>
                    {type ==="insight" ? (

                        <>
                        {results.map((res, index) => (

                                <Insight key={res.id} insight={res} setReference={setReference}  toggle_like={toggle_like} type={'following'}  setInsight={setCurrentInsight} mode="search" />
                                                    

                        ))}

                        </>
                        
                    ):(

                          <>
                            {results.map((result, index) => (
                                
                                <div key={index} style={{borderBottom: '1px solid #ccc', padding: '10px 0', display: 'flex', alignItems: 'center'}} onClick={()=>  navigate(`/${result.username}`)}>
                                <img src={result.photo || `${process.env.PUBLIC_URL}/avatar.jpg` } alt="Profile" className="rounded-circle" style={{marginRight: '10px', borderRadius:'7px'}} />
                                <div style={{marginLeft: 'auto'}}>
                                    <span style={{display: 'block', marginBottom: '3px'}}><b>{result.first_name} {result.last_name}</b></span>
                                    <span style={{display: 'block', marginBottom: '5px', fontSize:"smaller"}}>@{result.username}</span>
                                    {/* Additional content for each item */}
                                </div>
                            </div>

                            ))}
                            
                            </>

                    )}
                    </div>

                    {has_More && results.type && <LoadMore func={()=> load_more()} text={"Load more results"} /> }

                </div>
            )}
            
        </div>
 
               
                      </div>
                     
                    </div>
                    </div>
               

              )}

{reference !== null && <RefModal data={reference}  close ={close_ref_modal} />}

      <Footer page="search" />
    
    </div>

  );
};

