import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';


function ResetModal({ close, show, passcode, email }) {

    const navigate = useNavigate();


  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const [is_valid, setIsValid] = useState(null);
  const [msg, setMsg] = useState(null);


  //console.log(passcode, email)


  const show_login = () => {

    close("login")
    
  }


  const validateResetLink = async () => {

      try{  
        
        const response = await fetch('https://choralty.com/api/schat/validate-reset-link.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Change content type to application/json
          },
          body: JSON.stringify({
            email: email,
            passcode: passcode,
          }),
        });
    
        const responseText = await response.json(); // Log the raw response text
        //console.log('rvalidate reset link raw response:', responseText);

         const data = responseText //JSON.parse(responseText);
         if(data.success && data.success == true){

          //console.log(data)

          setIsValid(true)


         }else{

           setIsValid(false) 
          
         }

      } catch (error) {
         console.error('Error sending request:', error);
         setIsValid(false)
      }

  
      
};
  
 
const save_password = async () => {

    try{  
      
      const response = await fetch('https://choralty.com/api/schat/save-password.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Change content type to application/json
        },
        body: JSON.stringify({
          email: email,
          password: password1,
        }),
      });
  
      const responseText = await response.json(); // Log the raw response text
     // console.log('rvalidate reset link raw response:', responseText);

       const data = responseText //JSON.parse(responseText);
       if(data.success && data.success == true){

         // console.log(data)
          setMsg({'message': 'Your password has been updated successfully. please proceed to login.', 'mode': 'success'}) 
           navigate('/');

       }else{

         setMsg({'message': data.messages, 'mode': 'danger'}) 
        
       }

    } catch (error) {
       console.error('Error sending request:', error);
       setMsg({'message': 'Error sending request', 'mode': 'danger'}) 
    }


    
};
 
 useEffect(() => {

  if(passcode){

    validateResetLink()

  }

  }, []);


  useEffect(() => {

     if(password1 !== password2 ){

        setMsg({'message': 'Both Password should match', 'mode': 'danger'})

     }else if( password1.length > 2 && (password1.length < 8 || password1.length > 30) ){

        setMsg({'message': 'Password should be at least 8 chars and no more than 30 chars', 'mode': 'danger'})

     }else{

        setMsg(null)
     }

  }, [password1, password2]);
  


  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(!msg){

        save_password()
    }
    //console.log({ password1, password2 });
  };

  return (
    <div
    className={`modal ${show ? ' show' : ''}`}
    tabIndex="-1" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Set / Reset password</h5>
            <button type="button" className="btn-close" onClick={close}></button>
          </div>
          <div className="modal-body">

    {is_valid &&
            <form onSubmit={handleSubmit}>

                {msg &&
                     <div className="mb-3">
                     <div className={`alert alert-${msg.mode} d-flex align-items-center`} role="alert">
                               
                     {msg.mode === "danger" ? (
                                    <i className="fas fa-exclamation-triangle fa-2x mr-3"></i>
                                ) : (
                                    <i className="fas fa-check-circle fa-2x mr-3"></i>
                                )}

                               <div style={{marginLeft: '14px'}}>
                                   {msg.message}
                               </div>
                               </div>
                     </div>
                }

{ (msg == null || (msg.mode && msg.mode == "danger")) &&   

<>
              <div className="mb-3">
                <label htmlFor="password1" className="form-label">Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="pasword1"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password2" className="form-label">Re-Enter Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password2"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                />
              </div>
 
              <button type="submit" className="mt-3 btn btn-primary b-primary w-100">Save password</button>
</>
            }

{msg !== null && msg.mode && msg.mode !== "danger" && 
  <button type="button" className="mt-3 btn btn-primary b-primary w-100" onClick={show_login}>Login</button>
}

            </form>
    }

{is_valid !== null && !is_valid &&
<>
              <div className="mb-3">
              <div className="alert alert-warning d-flex align-items-center" role="alert">
                        <i className="fas fa-exclamation-triangle fa-2x mr-3"></i>
                        <div>
                            This verification link is either incorrect or expired.
                        </div>
                        </div>
              </div>
     
              <button type="button" className="mt-3 btn btn-primary b-primary w-100" onClick={()=>close('reset')}>Resend Link</button>
</>
    }

{is_valid == null  &&
<>
<div className="mb-3 text-center">
      <i className="fas fa-spinner fa-spin" style={{fontSize: '20vw'}}></i>
      <p style={{padding:'23px'}}>Validating passcode...</p>
    </div>
     
</>
    }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetModal;
