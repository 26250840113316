import { faSun } from '@fortawesome/free-regular-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

const ThemeToggle = () => {

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {

    //console.log('tg mode', localStorage.getItem('darkMode'))

    const storedDarkMode = localStorage.getItem('darkMode');
    
    if (storedDarkMode === 'true') {
      setDarkMode(true);
      document.body.classList.add('dark-mode');
    }

  }, []);

  useEffect(() => {

    if (darkMode) {
      document.body.classList.add('dark-mode');
      localStorage.setItem('darkMode', 'true');
    } else {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('darkMode', 'false');
    }
    
  }, [darkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <button onClick={toggleTheme} className='btn btn-primary b-primary'>
      <span 
            className="tg-btn" 
            style={{
              display: 'inline-block',
              width: '175px', // Set this width to accommodate the longest text
              whiteSpace: 'nowrap',
              textAlign: 'center' // Center the text
            }}
          >
            <span style={{marginRight: 12}}>{darkMode ? (<FontAwesomeIcon icon={faSun} />) : (<FontAwesomeIcon icon={faMoon} />)}</span>
            {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          </span>
    </button>
  );
};

export default ThemeToggle;
