import React, { useState } from 'react';

function LogInModal({ close, show, setUser }) {


  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');

  const [msg, setMsg] = useState(null);


  const show_signup = () => {

    close("signup")
    setMsg(null)
    
  }

  const show_req = () => {

    close("reset")
    setMsg(null)
    
  }


  const login = async () => {

    try{  
      
      const response = await fetch('https://choralty.com/api/schat/authenticate.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Change content type to application/json
        },
        body: JSON.stringify({
          email: username,
          password: password,
          mode: 'email'
        }),
      });
  
      const responseText = await response.json(); // Log the raw response text
      //console.log('validate reset link raw response:', responseText);

       const data = responseText //JSON.parse(responseText);
       if(data.success && data.success == true){

          //console.log(data)
          setUser(data)
          setMsg({'message': 'login successful', 'mode': 'success'}) 
           //navigate('/');

       }else{

         setMsg({'message': data.messages, 'mode': 'danger'}) 
        
       }

    } catch (error) {
       //console.error('Error sending request:', error);
       setMsg({'message': 'Error sending request. Please check your internet connection and retry.', 'mode': 'danger'}) 
    }


    
};



  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(password && username){
       login()
    }
    //console.log({ password, username });
  };

  return (
    <div
    className={`modal ${show ? ' show' : ''}`}
    tabIndex="-1" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Sign in</h5>
            <button type="button" className="btn-close" onClick={close}></button>
          </div>
          <div className="modal-body">

          {msg &&
                     <div className="mb-3">
                     <div className={`alert alert-${msg.mode} d-flex align-items-center`} role="alert">
                               
                     {msg.mode === "danger" ? (
                                    <i className="fas fa-exclamation-triangle fa-2x mr-3"></i>
                                ) : (
                                    <i className="fas fa-check-circle fa-2x mr-3"></i>
                                )}

                               <div style={{marginLeft: '14px'}}>
                                   {msg.message}
                               </div>
                               </div>
                     </div>
                }

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="userName" className="form-label">Email:</label>
                <input
                  type="text"
                  className="form-control"
                  id="userName"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
 
              <button type="submit" className="mt-3 btn btn-primary b-primary w-100">Sign in</button>

              <button type="button" className="mt-3 w-100 btn txt-btn"  onClick={show_req}>Forgot password?</button>

              <button type="button" className="mt-3 btn w-100 txt-btn" onClick={show_signup}>Create Account</button>


            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogInModal;
