import React, { useState, useEffect } from 'react';

export default function DeleteModal({ close, delete_fn, text, id , del_msg = false}) {

  //console.log('del msg', del_msg)

  
  return (
    <div
    className={`modal show`}
    tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Delete Action</h5>
            <button type="button" className="btn-close" onClick={close}></button>
          </div>

          <div className="modal-body">

          {del_msg.mode &&

                     <div className="mb-3">
                     <div className={`alert alert-${del_msg.mode} d-flex align-items-center`} role="alert">
                               
                     {del_msg.mode === "danger" ? (
                                    <i className="fas fa-exclamation-triangle fa-2x mr-3"></i>
                                ) : (
                                    <i className="fas fa-check-circle fa-2x mr-3"></i>
                                )}

                               <div style={{marginLeft: '14px'}}>
                                   {del_msg.message}
                               </div>
                               </div>
                     </div>
                }

            <p>{text}</p>

            <button className='btn btn-outline btn-outline-dark' onClick={()=>delete_fn(id)}>Yes</button>
            <button className='btn btn-dark' onClick={() => close()} style={{marginLeft: 14}}>No</button>

            
           
          </div>
        </div>
      </div>
    </div>
  );
}

