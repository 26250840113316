import '@babel/polyfill';
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MainContext from './MainContext';
import ErrorBoundary from './ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import ConfirmationModal from './confirmationModal';

const AppWrapper = () => {
  const [showModal, setShowModal] = useState(false);
  const [registration, setRegistration] = useState(null);

  const handleUpdate = (registration) => {
    setShowModal(true);
    setRegistration(registration);
  };

  const handleConfirm = () => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <GoogleOAuthProvider clientId='381223270420-ov99v4mafsptg4fu39fidivic6geniua.apps.googleusercontent.com'>
      <React.StrictMode>
        <MainContext>
          <ErrorBoundary>
            <App />
            {showModal && (
              <ConfirmationModal
                message="New version available. Refresh to update?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
              />
            )}
          </ErrorBoundary>
        </MainContext>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

// Move the handleUpdate definition here to make it accessible within the register call
const handleUpdate = (registration) => {
  const rootInstance = root._internalRoot.current.child.stateNode;
  rootInstance.setShowModal(true);
  rootInstance.setRegistration(registration);
};

serviceWorkerRegistration.register({
  onUpdate: handleUpdate,
});
