import React, { useState, useEffect } from 'react';

const BibleVerses = () => {
  const [verse, setVerse] = useState('');
  const [book, setBook] = useState('');
  const [chapter, setChapter] = useState('');
  const [verseNumber, setVerseNumber] = useState('');


  const fetchRandomVerse = async () => {
    try {

      const bookFiles = [
          // Old Testament
          'Genesis.json',
          'Exodus.json',
          'Leviticus.json',
          'Numbers.json',
          'Deuteronomy.json',
          'Joshua.json',
          'Judges.json',
          'Ruth.json',
          '1Samuel.json',
          '2Samuel.json',
          '1Kings.json',
          '2Kings.json',
          '1Chronicles.json',
          '2Chronicles.json',
          'Ezra.json',
          'Nehemiah.json',
          'Esther.json',
          'Job.json',
          'Psalms.json',
          'Proverbs.json',
          'Ecclesiastes.json',
          'SongOfSolomon.json',
          'Isaiah.json',
          'Jeremiah.json',
          'Lamentations.json',
          'Ezekiel.json',
          'Daniel.json',
          'Hosea.json',
          'Joel.json',
          'Amos.json',
          'Obadiah.json',
          'Jonah.json',
          'Micah.json',
          'Nahum.json',
          'Habakkuk.json',
          'Zephaniah.json',
          'Haggai.json',
          'Zechariah.json',
          'Malachi.json',
          // New Testament
          'Matthew.json',
          'Mark.json',
          'Luke.json',
          'John.json',
          'Acts.json',
          'Romans.json',
          '1Corinthians.json',
          '2Corinthians.json',
          'Galatians.json',
          'Ephesians.json',
          'Philippians.json',
          'Colossians.json',
          '1Thessalonians.json',
          '2Thessalonians.json',
          '1Timothy.json',
          '2Timothy.json',
          'Titus.json',
          'Philemon.json',
          'Hebrews.json',
          'James.json',
          '1Peter.json',
          '2Peter.json',
          '1John.json',
          '2John.json',
          '3John.json',
          'Jude.json',
          'Revelation.json'
        ];
        

      // Select a random book file
      const randomBookFile = bookFiles[Math.floor(Math.random() * bookFiles.length)];

      // Fetch the JSON content of the random book file
      const response = await fetch(`${process.env.PUBLIC_URL}/Bible/${randomBookFile}`);
      const bookData = await response.json();

      // Select a random chapter and verse
      const randomChapter = bookData.chapters[Math.floor(Math.random() * bookData.chapters.length)];
      const randomVerse = randomChapter.verses[Math.floor(Math.random() * randomChapter.verses.length)];

      // Update the state with the selected verse
      setBook(bookData.book);
      setChapter(randomChapter.chapter);
      setVerseNumber(randomVerse.verse);
      setVerse(randomVerse.text);

    } catch (error) {
      console.error('Error fetching verse:', error);
    }
  };


  useEffect(() => {
    // Fetch a random verse immediately
    fetchRandomVerse();

    // Set up an interval to fetch a new verse every 30 seconds
    const intervalId = setInterval(() => {
      fetchRandomVerse();
    }, 5 * 1000); // 30000 milliseconds = 30 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className ="container-fluid bible-verses">
    <div className ="row my-5">

      <div className ="col-md-12 text-center" style={{padding:'0 20%'}}>
      <h2>{book} {chapter} : {verseNumber}</h2>
      <blockquote><h5>{verse}</h5></blockquote>

    </div>
    </div>
    </div>
  );
};

export default BibleVerses;
