import React from 'react';

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faComment, faHeart, faShare, faTrash, faEllipsisV, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { openReference, timeAgo } from '../utils/functions';
import { Dropdown } from 'react-bootstrap';

export default function Insight ({ share, insight, setReference, toggle_like, setInsight, type, delete_insight=false, edit_insight, mode=""})  {

    const p_url = "https://clowza.com";

    const navigate = useNavigate();

    const open_ref = async (ref) => {


        const ref_ = await openReference(ref)

        if(ref_){
           
            setReference(ref_)
        }

    } 


    const goto_insight = (insight) =>{
        
        setInsight(insight)
       
        navigate(`/${insight.username}/insight/${insight.id}`)

    }


    

    return(

    <div key ={insight.id} className="d-flex mb-4 pb-4 border-bottom">

      <div className="me-3" onClick={()=> navigate(`/${insight.username}`)}>
        <img src={insight.photo || `${process.env.PUBLIC_URL}/avatar.jpg`} alt="Profile" className="rounded-circle" />
      </div>

      <div className="w-100 ">
      


      <div className="d-flex align-items-center">

        <span className="fw-bold me-2">{insight.name}</span>
        <span className="sc-text-muted me-2" onClick={() => goto_insight(insight)}>{`@${insight.username}`}</span>
        <span className="sc-text-muted">{timeAgo(insight.time_posted)}</span>

        {type === "mine" && (

          <Dropdown className="ms-auto">
            <Dropdown.Toggle variant="link link-dark" bsPrefix="p-0">
              <FontAwesomeIcon icon={faEllipsisV} />
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={() => edit_insight(insight)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" />
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => delete_insight(insight.id)} >
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
   
  
        {insight.passages.length >  5 && 
        
        <div className="d-flex_ justify-content-between sc-text-muted my-2" >
  
            {JSON.parse(insight.passages).map((ref, index) => (
  
            <span key={index} className="b-primary ref-btn" style={{ display: 'inline-block', padding: '0px 5px', margin: '2px 0',  alignItems: 'center', marginRight:'15px', borderRadius:'7px' }}>
  
                  <span  onClick={() => open_ref(ref)} style={{color:'white'}}>  {ref.book} {ref.chapter}:{ref.verse1}{`${parseInt(ref.verse2) > parseInt(ref.verse1)? `-${ref.verse2}` : ''}`}</span>
  
                <FontAwesomeIcon
                    icon={faBook}
                    style={{ cursor: 'pointer', color: 'white' , marginLeft:'10px', fontSize:'smaller'}}
                />
            </span>
            ))}
        </div>
       }
  
        <div className="mt-2" onClick={()=> goto_insight(insight)}>
          <p dangerouslySetInnerHTML={{ __html: `${insight.insight.length < 150 ? insight.insight : insight.insight.substring(0,150)+" ...<b>read more</b>" }` }} />
        </div>
  
    
        {mode !== "search" && <div className="d-flex justify-content-between sc-text-muted mt-2 insight-icons-div ">
          <span> <FontAwesomeIcon icon={faComment}  onClick={() => goto_insight(insight)} /> {insight.total_comments}</span>
          <span onClick={()=>toggle_like(insight.id, type)}> <FontAwesomeIcon style={{color: insight.user_liked > 0 ? '#48887b': 'unset'}} icon={insight.user_liked > 0 ? faHeart : farHeart} /> {insight.total_likes}</span>
          <span onClick={()=>share( `${p_url}/${insight.username}/insight/${insight.id}`)}> <FontAwesomeIcon icon={faShare} /> {'Share'}</span>
        </div>}

      </div>
    </div>
    )
};
  

 