function cookieParser(parseIt){
var match = document.cookie.match(new RegExp('(^| )' + parseIt + '=([^;]+)'));
if(match){
  return match[2]
}
}


const getTimeStamp = ()=>{

  var timestamp = changeTimezone(new Date(), 'Europe/London')
  var year = timestamp.getFullYear();
  var month = timestamp.getMonth() + 1;
  var day = timestamp.getDate();

  return year+""+ month+""+ day
}



function changeTimezone(date, d_tz) {

  // suppose the date is 12:00 UTC
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: d_tz
  }));

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  var diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract

}

function capitalise(str){
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}


const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function datediff(first, second) {        
  return Math.abs(Math.round((second - first) / (1000 * 60 * 60 * 24)));
}

const isValidEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

  
  const copyHash = (_id, txt) =>{

    navigator.clipboard.writeText(_id)
  
    alert(txt+' copied!')
  
  }

  const openReference = async (ref) => {

    try {

      // Fetch the JSON content of the random book file
      const response = await fetch(`${process.env.PUBLIC_URL}/Bible/${ref.book}.json`);
      const bookData = await response.json();

      const chapter = bookData.chapters[ref.chapter-1]

      //console.log("ch",ref, chapter, chapter.verses[ref.verse2 - 1])

      let verse = "";

      let num = 0;

      if(parseInt(ref.verse2) >= parseInt(ref.verse1)){

            for (let i = ref.verse1 - 1; i < ref.verse2; i++) {
                // Concatenate each verse to the string
                num++
                 let append = "";
                if(num > 1){
                  append = '<br/><br/>'
                }
                verse += append+(chapter.verses[i].verse)+". "+(chapter.verses[i].text) + ' ';
            }

        }else{

          //
        }

            //console.log(ref,verse);

            return {'title': `${ref.book} ${ref.chapter} vs ${ref.verse1} ${parseInt(ref.verse2) > parseInt(ref.verse1) ? ` - ${ref.verse2}` : ""}`, 'body': verse}


    } catch (error) {
      //console.error('Error fetching verse:', error);
      return false
    }
  };

 

  function timeAgo(dateString) {
    
    const isoDateString = new Date(dateString.replace(' ', 'T')).toISOString();

    //return dateString.replace(' ', 'T')
  
    // Create Date objects for comparison
    const date = new Date(isoDateString);
    const now = new Date();

    // Calculate the difference in seconds
    const diffInSeconds = Math.floor((now - date) / 1000);

    // Define time unit constants
    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;

    // Time difference calculations
    const days = Math.floor(diffInSeconds / secondsInDay);
    const hours = Math.floor((diffInSeconds % secondsInDay) / secondsInHour);
    const minutes = Math.floor((diffInSeconds % secondsInHour) / secondsInMinute);
    const seconds = diffInSeconds % secondsInMinute;

    // If the difference is greater than a week, format the date
    const diffInYears = now.getFullYear() - date.getFullYear();
    
    if (days >= 7 && 3==4) {
        const options = { month: 'short', day: 'numeric' };
        if (diffInYears >= 1) {
            options.year = 'numeric';
        }
        return date.toLocaleDateString(undefined, options);
    }

    if (days >= 7) {
      // Use a simple date format to avoid issues with locale
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();

      if (diffInYears >= 1) {

            return `${month} ${day}, ${year}`;
      }else{
            return `${month} ${day}`;
      }
     
  }


    // Return formatted time ago string
    if (days > 0) {
        return `${days}d`;
    } else if (hours > 0) {
        return `${hours}h`;
    } else if (minutes > 0) {
        return `${minutes}m`;
    } else {
        return `${seconds}s`;
    }
}

  
 

  const validateURL = (value) => {

    try {
        new URL(value);
        return true;
    } catch (e) {
        return false;
    }
};

 
module.exports = {
  copyHash,
  isValidEmail,
  datediff,
  numberWithCommas,
  cookieParser,
  getTimeStamp,
  changeTimezone,
  capitalise,
  openReference, 
  timeAgo,
  validateURL
}