// LoadingScreen.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import './LoadingScreen.css';

const Loading = () => {
    return (
        <div className="loading">
            <FontAwesomeIcon icon={faSpinner} spin size="3x"  color='white' />
        </div>
    );
};

export default Loading;
