import React from 'react';
import { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faCopy, faX,  } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

export default function ShareModal({ close, post_url }) {

    const [is_copied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(post_url);
    setIsCopied(true)
  };

  const handleClose = () => {

      setIsCopied(false)
      close()
  }

  const shareOnWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(post_url)}`, '_blank');
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(post_url)}`, '_blank');
  };

  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(post_url)}`, '_blank');
  };

  return (
    <div
      className={`modal show`}
      tabIndex="-1"
      style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Share This Insight</h6>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>

          <div className="modal-body">
            <div className="input-group mb-3">
              <input type="text" className="form-control" value={post_url} readOnly />
              <button className="btn btn-outline-secondary" onClick={copyToClipboard}>

              <FontAwesomeIcon icon={faCopy} style={{color: is_copied? 'green': 'unset'}}  />

              </button>
            </div>
            <div className="d-flex justify-content-around mt-3">
              <button className="btn btn-outline-success sh-btn" onClick={shareOnWhatsApp}>
              <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
              </button>
              <button className="btn btn-outline-dark sh-btn" onClick={shareOnTwitter}>
              <FontAwesomeIcon icon={faX} /> Twitter
              </button>
              <button className="btn btn-outline-primary sh-btn" onClick={shareOnFacebook}>
              <FontAwesomeIcon icon={faFacebook}  /> Facebook
              </button> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
