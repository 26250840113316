import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCog, faDownload } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'

import ThemeToggle from './toggle_theme';

export default function SettingsModal({ close }) {


    const navigate = useNavigate();


    const go_to_edit = () => {

        navigate("/")
    }


  return (
    <div
    className={`modal  show`}
    tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">Settings</h6>
          
            <FontAwesomeIcon className="ms-auto" icon={faClose} onClick={close} />
          </div>

       
          <div className="modal-body mb-2" style={{minHeight:'50vh'}}>


            <ThemeToggle />


           {3 < 2 &&  <button onClick={go_to_edit} className='btn btn-outline btn-outline-dark'>
                    <FontAwesomeIcon icon={faCog} />
                    
                    <span style={{marginLeft:12}}>Edit Profile</span>
            </button> }


            <div className="results-help text-left my-4 refs-div" style={{fontSize:'smaller'}}>
                
                    <h6><b>How to install App</b></h6>
                    <hr/>
                    <p><b>Android: </b> Click on "Add to Homescreen" on your browser menu.</p>
                    <p><b>IPhone</b>: Click on the share button then "Add to Homescreen"</p>
                    <p>Need assistance or have feedback? Please send an email to <a href="mailto:support@clowza.com"><b>support@clowza.com</b></a> or <a href="mailto:clowzamail@gmail.com"><b></b><b>clowzamail@gmail.com</b></a></p>
                </div>

         <p className='text-center' style={{fontSize:'smaller'}}>Clowza  v1.0.1 </p>

          </div>
        </div>
      </div>
    </div>
  );
}

