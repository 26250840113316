// LoadingScreen.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadMore = ({view, func, text}) => {

    return (
        <div className="loadmore text-center" onClick={() => func(view)} style={{padding: '5px 14px', border: '1px solid grey', borderRadius: 12}} >
            <span>{text}</span>
        </div>
    );
};

export default LoadMore;
