 // LoadingScreen.js
 import React  from 'react';

 import { useContext } from "react";
import { MyContext } from "../MainContext";
  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from "react-router-dom";

 
 const Header = () => {

    const {user, exit_user} = useContext(MyContext);

    const navigate = useNavigate();

    const exit_acc = () => {

        exit_user()
        navigate("/")
    }
 
 
     return (
    <>
          {/* Top Navigation */}
          <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container d-flex justify-content-between align-items-center">
                    {user && user.user_id ? (<FontAwesomeIcon icon={faChevronLeft} 
                    
                    onClick={() => {

                    if (window.history.length > 1) {
                        navigate(-1); // Go back one page
                    } else {
                        navigate("/"); // Navigate to the root URL
                    }
                    }} /> ) :
                    (<FontAwesomeIcon icon={faChevronLeft} onClick={() => navigate("/")} />)}

                    <a className="navbar-brand mx-auto" onClick={() => navigate("/")} >
                    <img src={`${process.env.PUBLIC_URL}/logo_wordmark_color.svg`} alt="Placeholder Image" className="img-fluid hm-logo" style={{ maxWidth: '150px' }} />
                    </a>

                    <FontAwesomeIcon icon={faPowerOff} onClick={exit_acc} />
                </div>
    </nav>
        </>
        
         
     );
 };
 
 export default Header;
 