import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export default function RefModal({ close, data }) {


  return (
    <div
    className={`modal  show }`}
    tabIndex="-1" style={{ display: 'block' , backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{data.title}</h5>
            <button type="button" className="btn-close" onClick={close}></button>
          </div>
          <div className="modal-body">
  
              <div className="mb-3" dangerouslySetInnerHTML={{ __html: data.body }} />
              

        
          </div>
        </div>
      </div>
    </div>
  );
}

