import { useState, useContext, useEffect } from "react";
import './App_1.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter, Routes, Route} from "react-router-dom";

import { MyContext } from "./MainContext";

import AuthPage from './pages/authPage';
import Home from "./pages/home";
import Profile from "./pages/profile";
import Insight from "./pages/insight";

import SearchPage from "./pages/search";

import AboutPage from "./pages/about";
import PrivacyPage from "./pages/privacy_policy";
import DonatePage from "./pages/donate";


function App() {

  const {setUser, user} = useContext(MyContext);


  return (
  
         <BrowserRouter>
         
        <Routes>

          {!user.user_id && <Route path="/" element={<AuthPage />} />}
          {user.user_id && <Route path="/" element={<Home />} />}
          

          <Route path="/:username" element={<Profile />} />

          <Route path="/:username/insight/:insight_id" element={<Insight />} />

          <Route path="/confirm-email/:email/:passcode" element={<AuthPage />} />

          <Route path="/search/:query" element={<SearchPage />} />

          <Route path="/search" element={<SearchPage />} />

          <Route path="/about" element={<AboutPage />} />

          <Route path="/privacy-policy" element={<PrivacyPage />} />

          <Route path="/support" element={<DonatePage />} />
         
         </Routes>

      </BrowserRouter>

  );
}

export default App;
