import React from 'react';

import Footer from '../components/footer';

import Header from '../components/header';
import { useParams, useNavigate } from 'react-router-dom';


export default function AboutPage () {

    const { query } = useParams();
    const navigate = useNavigate();

  return (
   
    <div className="container">

       <Header />
          
   
                  <div className="container-fluid mt-5 pt-3">
                    <div className="row">

                      <div className="col-md-12 col-sm-12 border p-3">
                      <section className="py-5">
                        <div className="container">
                        <div className="text-justify text-div">
                            <h2 className="mb-4">Welcome to Clowza!</h2>
                            <p className="lead">
                            In a world where social media can often seem like a continuous flow of distractions and unrelated content, Clowza was born from a genuine concern. Many individuals struggling to stay focused on what truly matters to them: their faith and spiritual growth.
                            </p>
                            <p className="lead">
                            At Clowza, we're here to change that narrative. We've created a vibrant community where every post, every interaction is grounded in Scripture. Imagine a space where your social experience isn't just enriching but also deepens your connection with God. That's what Clowza is all about.
                            </p>
                            <p className="lead">
                            Join us as we embark on a journey to reclaim our time online for meaningful engagement. Here, you'll find insights and discussions that nourish your soul and inspire your spirit. It's more than a platform; it's a sanctuary where you can be social and stay centered on what's essential—your relationship with God.
                            </p>
                            <p className="lead">
                            Thank you for being part of our community. Spread the word and invite fellow believers worldwide to join us on this transformative path at Clowza.
                            </p>
                            
                          
                            <div className="mt-5_" style={{marginTop:'80px'}} onClick={()=>navigate(`/rudolph_edafe`)} >
                            <img src={`${process.env.PUBLIC_URL}/rudolph_edafe.jpg`} alt="Your Name" className="mb-1" style={{ width: '100px', borderRadius:24 }} />
                            <h6 className="mt-2">Rudolph Edafe</h6>
                            <img src={`${process.env.PUBLIC_URL}/sgn.png`} alt="Your Signature" className="img-fluid_ mt-0 d-none" style={{ maxWidth: '100px' }} />
                            </div>
                        </div>
                        </div>
                    </section>
               
                      </div>
                 
                    </div>
                    </div>
    


      <Footer page="about" />
    
    </div>

  );
};

