import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle, faTrashAlt, faWarning } from '@fortawesome/free-solid-svg-icons';

import RefModal from './reference';

import { openReference, timeAgo } from '../utils/functions';

export default function PostModal({ close, show, user_id , refresh_my_insights , reset_post, is_edit, post}) {

  const [body, setBody] = useState('');

  const [book_data, setBookData] = useState('');

  const [book, setBook] = useState('');

  const [add_ref, setAddRef] = useState(false)

  const [chapters, setChapters] = useState(0);
  const [chapter, setChapter] = useState(0);

  const [verses, setVerses] = useState(0);
  const [verse1, setVerse1] = useState(0);
  const [verse2, setVerse2] = useState(0);

  const [references, setReferences] = useState([]);
  const [reference, setReference] = useState(null);

  const [msg, setMsg] = useState(null);

  const [verseOptions, setVerseOptions] = useState([]);
  
  const [chapterOptions, setChapterOptions] = useState([]);

  const [is_posting, setPosting] = useState(false)



  const reset_ref = () => {

    setChapter(0)
      setVerse1(1)
      //setVerse2(1)
      setBook('')
      setMsg({})
    
}


const reset = () => {

      setReferences([])
      setBody('')

      reset_ref()
     
      
  }

  const close_modal = () => {

    reset()

    close()
}

  useEffect(() => {

         
  if(is_edit){

    //console.log('is edit', post)
    setReferences(JSON.parse(post.passages))
    setBody(post.insight)

  }else{

       // console.log('not edit', post)
        
        if(reset_post){
          reset()
      }
  }


  }, [is_edit]); 


  useEffect(() => {

    if(verse1 > 0){

    setVerse2(verse1)

    }

  }, [verse1]); 


  useEffect(() => {

    const options = Array.from({ length: verses }, (_, i) => i + 1).map((verse) => (
      <option key={verse} value={verse}>{verse}</option>
    ));
    setVerseOptions(options);

  }, [verses]); 


  useEffect(() => {

    fetchChapterVerses(1)

    const options = Array.from({ length: chapters }, (_, i) => i + 1).map((chapter) => (
      <option key={chapter} value={chapter}>{chapter}</option>
    ));

    setChapterOptions(options);

  }, [chapters]);


  useEffect(() => {

   //console.log('ref', references)

  }, [references]);


  useEffect(() => {

    //console.log("chp", book_data)

    if(book_data !== ""){

        const bookChapter = book_data.chapters.length;

        //console.log("chp", bookChapter)

        setChapters(bookChapter);

    }

  }, [book_data]); 


   const close_ref_modal = () => {
     
       setReference(null)

   }


 

  const update_ref = async () => {

    setMsg({})

    try {

      if(book && chapters && verses){


        if(book.length < 3){

          setMsg({'msg': `Please select Book (e.g Genesis)`, 'mode': 'danger'})

          return
      }
         
        if(parseInt(verse1) > parseInt(verse2)){

            setMsg({'msg': `verses ${verse1} - ${verse2} ? should be verses ${verse2} - ${verse1}`, 'mode': 'danger'})

            return
        }
  
                const newJsonObject = {
                    book: book.length > 2 ? book : "Genesis",
                    chapter: chapter > 0 ? chapter : 1,
                    verse1: verse1 > 0 ? verse1 : 1,
                    verse2: verse2 > 0 ? verse2 : 1
                };


                const isDuplicate = references.find(ref => 
                    ref.book === newJsonObject.book &&
                    ref.chapter === newJsonObject.chapter &&
                    ref.verse1 === newJsonObject.verse1 &&
                    ref.verse2 === newJsonObject.verse2
                );
        
                if (!isDuplicate) {

                  if(references.length === 10){
                     
                    setMsg({"msg": "You can only include up to 10 references in an insight.", "mode": "danger"});

                    return
                     
                  }

                    setAddRef(false)
                    setReferences([...references, newJsonObject]);

                    reset_ref()

                } else {

                    setMsg({"msg": "Duplicate entry", "mode": "danger"});
                }


      }else{

              setMsg({'msg': `Please select Book (e.g Genesis)`, 'mode': 'danger'})
      
      }

    } catch (error) {


      
      console.error('Error fetching verse:', error);
    }
  };


  const open_ref = async (ref) => {


    const ref_ = await openReference(ref)

    if(ref_){
       
        setReference(ref_)
    }

} 



  const fetchBookChapters = async (title) => {

    try {

      setBook(title.replace(" ", ""))

      // Fetch the JSON content of the random book file
      const response = await fetch(`${process.env.PUBLIC_URL}/Bible/${title.replace(" ", "")}.json`);
      const bookData = await response.json();

      setBookData(bookData)

    } catch (error) {
      console.error('Error fetching verse:', error);
    }
  };

  
  const fetchChapterVerses = async (num) => {

    if(num < 1){
      return
    }

    try {

      // Fetch the JSON content of the random book file
      const bookData = book_data;

      // Select a random chapter and verse
      const bookVerses = bookData ? bookData.chapters[num-1].verses.length : 1;
   
      //console.log(bookVerses)

      setVerses(bookVerses);

      setChapter(num);


    } catch (error) {
      console.error('Error fetching verse:', error);
    }
  };


  const fetchChapters = (book) => {

    //console.log(book)
      
    fetchBookChapters(book)

  }

  const fetchVerses = (num) => {

    //console.log(num)
      
    fetchChapterVerses(num)

  }


  const refTexts = (data) =>{

      data.map(({ book, chapter, verse1, verse2 }) => {
      const verseRange = verse2 && verse1 !== verse2 ? `${verse1}-${verse2}` : verse1;
      return `${book}${chapter}:${verseRange}`;
      });
}


  const handleSubmit = async (e) => {

    setMsg({})

   
    e.preventDefault();

    if(is_posting){
       
         return
    }

    if(references.length < 1){

      setMsg({'msg': 'Please provide at least 1 Bible reference.', 'mode': 'danger'})
      return
  }


    else if(body.length < 5){

        setMsg({'msg': 'Insight should be at least 5 chars', 'mode': 'danger'})
        return
    }

     setPosting(true)

     const passages_texts =  references.map(({ book, chapter, verse1, verse2 }) => {
      const verseRange = verse2 && verse1 !== verse2 ? `${verse1}-${verse2}` : verse1;
      return `${book}${chapter}:${verseRange}`;
      });

      const passages_text = passages_texts.join(',');

      //console.log(passages_text)
    
    
      try{  
        
        const response = await fetch('https://choralty.com/api/schat/publish.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Change content type to application/json
          },
          body: JSON.stringify({
            insight: body,
            passages: references,
            passages_text: passages_text,
            user_id: user_id,
            post_id: (is_edit && post) ? post.id : 0,
          }),
        });
    
        const responseText = await response.json(); // Log the raw response text
        //console.log('Raw response:', responseText);

    
         const data = responseText //JSON.parse(responseText);
         if(data.success && data.success == true){

          setPosting(false)

          reset()

          setMsg({'msg': 'Yay! Your Insight has been published', 'mode': 'success'})

          refresh_my_insights(true)

         }else{

          setMsg({'msg': data.messages , 'mode': 'danger'})

          setPosting(false)
          
         }

      } catch (error) {
         
        setPosting(false)

        setMsg({'msg': 'Error posting Insight. Please check your internet connection and retry' , 'mode': 'danger'})

         console.error('Error sending request:', error);
      }

  
      
};


const deleteReference = (index) => {
    const newReferences = references.filter((_, i) => i !== index);
    setReferences(newReferences);
};


  return (
    <div
    className={`modal ${show ? ' show' : ''}`}
    tabIndex="-1" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">{is_edit ? 'Edit insight': `What will you be sharing today?`}</h6>
            <button type="button" className="btn-close " onClick={close_modal}></button>
          </div>

          {reference !== null && <RefModal data={reference}  close ={close_ref_modal} />}
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
         
       
              <div className="mb-3">
                <textarea
                  type="text"
                  className="form-control"
                  id="email"
                  value={body}
                  placeholder='Type here...'
                  onChange={(e) => setBody(e.target.value)}
                  required
                />
              </div>

              
              <div className="mb-3 ">

                        <div className='refs' style={{ display_: 'flex'}}>

                        {references.map((ref, index) => (

                                <span key={index} className="b-primary ref-btn" style={{ display: 'inline-block', padding: '0px 5px', margin: '5px',  alignItems: 'center', marginRight:'15px', borderRadius:'7px' }}>

                                      <span  onClick={() => open_ref(ref)} style={{color:'white'}}>  {ref.book} {ref.chapter}:{ref.verse1}{`${parseInt(ref.verse2) > parseInt(ref.verse1)? `-${ref.verse2}` : ''}`}</span>

                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        onClick={() => deleteReference(index)}
                                        style={{ cursor: 'pointer', color: 'red' , marginLeft:'10px'}}
                                    />
                                </span>
                ))}
                            
                        </div>

                <hr/>

               {!add_ref && <button onClick={()=>setAddRef(!add_ref)} type="button" className="btn btn-primary w-20 b-primary rounded-circle_" style={{fontSize: 'small'}}><i className="fas fa-plus" style={{fontSize: 'small'}}></i> Add a Bible reference</button>}

               {add_ref && <div className="refs-div">

                <div className='form-inline '>
                <div className="form-group mr-2 ">
                    <select className="form-control" id="bookSelect"  onChange={(e) => fetchChapters(e.target.value)}>
                    <option value="">Choose</option>
                      <optgroup label="Old Testament">
                        <option value="Genesis">Genesis</option>
                        <option value="Exodus">Exodus</option>
                        <option value="Leviticus">Leviticus</option>
                        <option value="Numbers">Numbers</option>
                        <option value="Deuteronomy">Deuteronomy</option>
                        <option value="Joshua">Joshua</option>
                        <option value="Judges">Judges</option>
                        <option value="Ruth">Ruth</option>
                        <option value="1 Samuel">1 Samuel</option>
                        <option value="2 Samuel">2 Samuel</option>
                        <option value="1 Kings">1 Kings</option>
                        <option value="2 Kings">2 Kings</option>
                        <option value="1 Chronicles">1 Chronicles</option>
                        <option value="2 Chronicles">2 Chronicles</option>
                        <option value="Ezra">Ezra</option>
                        <option value="Nehemiah">Nehemiah</option>
                        <option value="Esther">Esther</option>
                        <option value="Job">Job</option>
                        <option value="Psalms">Psalms</option>
                        <option value="Proverbs">Proverbs</option>
                        <option value="Ecclesiastes">Ecclesiastes</option>
                        <option value="Song of Solomon">Song of Solomon</option>
                        <option value="Isaiah">Isaiah</option>
                        <option value="Jeremiah">Jeremiah</option>
                        <option value="Lamentations">Lamentations</option>
                        <option value="Ezekiel">Ezekiel</option>
                        <option value="Daniel">Daniel</option>
                        <option value="Hosea">Hosea</option>
                        <option value="Joel">Joel</option>
                        <option value="Amos">Amos</option>
                        <option value="Obadiah">Obadiah</option>
                        <option value="Jonah">Jonah</option>
                        <option value="Micah">Micah</option>
                        <option value="Nahum">Nahum</option>
                        <option value="Habakkuk">Habakkuk</option>
                        <option value="Zephaniah">Zephaniah</option>
                        <option value="Haggai">Haggai</option>
                        <option value="Zechariah">Zechariah</option>
                        <option value="Malachi">Malachi</option>
                      </optgroup>
                      <optgroup label="New Testament">
                        <option value="Matthew">Matthew</option>
                        <option value="Mark">Mark</option>
                        <option value="Luke">Luke</option>
                        <option value="John">John</option>
                        <option value="Acts">Acts</option>
                        <option value="Romans">Romans</option>
                        <option value="1 Corinthians">1 Corinthians</option>
                        <option value="2 Corinthians">2 Corinthians</option>
                        <option value="Galatians">Galatians</option>
                        <option value="Ephesians">Ephesians</option>
                        <option value="Philippians">Philippians</option>
                        <option value="Colossians">Colossians</option>
                        <option value="1 Thessalonians">1 Thessalonians</option>
                        <option value="2 Thessalonians">2 Thessalonians</option>
                        <option value="1 Timothy">1 Timothy</option>
                        <option value="2 Timothy">2 Timothy</option>
                        <option value="Titus">Titus</option>
                        <option value="Philemon">Philemon</option>
                        <option value="Hebrews">Hebrews</option>
                        <option value="James">James</option>
                        <option value="1 Peter">1 Peter</option>
                        <option value="2 Peter">2 Peter</option>
                        <option value="1 John">1 John</option>
                        <option value="2 John">2 John</option>
                        <option value="3 John">3 John</option>
                        <option value="Jude">Jude</option>
                        <option value="Revelation">Revelation</option>
                      </optgroup>
                    </select>
                  </div>

                        <div className="form-group mr-2 ">

                          <select className="form-control" id="chapter" onChange={(e) => fetchVerses(e.target.value)} >
                        
                         {chapterOptions}

                            
                        </select>
                        </div>

                        <div className="form-group mr-2">
                            <span style={{padding: '0 12px'}}> vs </span>
                        </div>

                          <div className="form-group mr-2 ">

                          <select className="form-control" id="verse1" value={verse1}  onChange={(e) => setVerse1(e.target.value)}>

                            {verseOptions}
                            
                        </select>
                        </div>

                        <div className="form-group mr-2">
                            <span style={{padding: '0 12px'}}> - </span>
                        </div>

                        <div className="form-group mr-2 ">

                                <select className="form-control" id="verse2" value={verse2}  onChange={(e) => setVerse2(e.target.value)}>

                                {verseOptions}
                                
                                </select>
                                </div>

                        

                 </div>

<div className="form-group mr-2">
<button onClick={update_ref} type="button" className="btn btn-primary w-20 b-primary rounded-circle_ mt-3" style={{fontSize: 'small'}}><i className="fas fa-plus" style={{fontSize: 'small'}}></i> Add to reference</button>
</div>
                 
                 </div>}

                 
               
              </div>

               {msg?.msg && <span style={{fontSize:'smaller'}} className={`text-${msg.mode}`}>{msg.mode === "danger" ?( <FontAwesomeIcon icon={faWarning} />) : (<FontAwesomeIcon icon={faCheckCircle} />)} {msg.msg}</span>}

              <button type="submit" className="mt-3 btn w-100 btn-primary b-primary">{`${is_posting === true ? 'Publishing Insight..': 'Publish Insight'}`}</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

