import React from 'react';

import Footer from '../components/footer';

import Header from '../components/header';


export default function PrivacyPage () {


  return (
   
    <div className="container">

       <Header />
          
   
                  <div className="container-fluid mt-5 pt-3">
                    <div className="row">

                      <div className="col-md-12 col-sm-12 border p-3">
     
                          <section className="py-5">
                            <div className="container">
                              <div className="text-justify text-div">
                                <h2 className="mb-4">Privacy Policy</h2>
                                <p className="lead">
                                  At Clowza, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our social media platform.
                                </p>

                                <h4>Information We Collect</h4>
                                <p>
                                  We collect the following types of personal information:
                                </p>
                                <ul>
                                  <li>Email address: Used for account creation, notifications, and communication.</li>
                                  <li>Photo (profile picture): Used to personalize your profile and interactions on the platform.</li>
                                  <li>Location (optional): Entered by the user for geolocation-based features or content.</li>
                                  <li>Date of Birth (optional): Used for age verification and to provide age-appropriate content.</li>
                                  <li>Name: Used to identify you on the platform and in communications.</li>
                                </ul>

                                <h4>How We Use Your Information</h4>
                                <p>
                                  We use the information we collect for the following purposes:
                                </p>
                                <ul>
                                  <li>To provide and personalize our services, including creating and managing your account.</li>
                                  <li>To communicate with you, including sending notifications, updates, and responding to inquiries.</li>
                                  <li>To improve our platform, develop new features, and analyze usage trends.</li>
                                  <li>To enforce our Terms of Service and other policies.</li>
                                  <li>To comply with legal obligations.</li>
                                </ul>

                                <h4>Sharing Your Information</h4>
                                <p>
                                  We do not sell your personal information to third parties. However, we may share your information in the following circumstances:
                                </p>
                                <ul>
                                  <li>With your consent or as directed by you.</li>
                                  <li>With service providers who assist us in operating our platform and providing services (e.g., hosting providers, customer support services).</li>
                                  <li>When required by law or to protect our rights, property, or safety, or that of our users or others.</li>
                                  <li>In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
                                </ul>

                                <h4>Security of Your Information</h4>
                                <p>
                                  We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                                </p>

                                <h4>Your Choices and Rights</h4>
                                <p>
                                  You have the right to access, correct, or delete your personal information. You may also choose to limit the ways we use and share your information.
                                </p>

                                <h4>Changes to This Privacy Policy</h4>
                                <p>
                                  We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                                </p>

                                <h4>Contact Us</h4>
                                <p>
                                  If you have any questions about this Privacy Policy, please contact us at support@clowza.com
                                </p>

                                <p>
                                  By using our platform, you consent to the collection and use of your information as described in this Privacy Policy.
                                </p>
                              </div>
                            </div>
                          </section>

                      </div>
                 
                    </div>
                    </div>
    


      <Footer />
    
    </div>

  );
};

