import React from 'react';
import './confirmationModal.css'; // Create this CSS file for styling

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="modal modal-c">
      <div className="modal-content modal-content-c">
        <p>{message}</p>
        <button className="button-c" onClick={onConfirm}>Yes</button>
        <button className="button-c" onClick={onCancel}>No</button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
