import React from 'react';

import Footer from '../components/footer';

import Header from '../components/header';
import { useParams, useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGift } from '@fortawesome/free-solid-svg-icons';

export default function DonatePage () {

    const { query } = useParams();
    const navigate = useNavigate();

  return (
   
    <div className="container">

       <Header />
          
   
                  <div className="container-fluid mt-5 pt-3">
                    <div className="row">

                      <div className="col-md-12 col-sm-12 border p-3">
                      <section className="py-5">
                        <div className="container">
                        <div className="text-justify text-div">
                          
                            <h4 className="mb-4">Support Us</h4>
                            <p className="lead">
                            Your contributions will support the resources necessary to maintain our servers and deliver regular updates and enhancements, ensuring an improved experience for all users.
                            </p>
                            
                            <p className="lead" style={{marginTop:34}}>
                            <a href="https://paystack.com/pay/clowza-usd" target="_blank" rel="noopener noreferrer" className="btn btn-primary b-primary"><b><FontAwesomeIcon icon={faGift} /> Donate USD </b></a> <a href="https://paystack.com/pay/clowza-ngn" target="_blank" rel="noopener noreferrer" className="btn btn-primary b-primary "><b><FontAwesomeIcon icon={faGift} /> Donate NGN </b></a>
                            </p>


                        </div>
                        </div>
                    </section>
               
                      </div>
                 
                    </div>
                    </div>
    


      <Footer />
    
    </div>

  );
};

