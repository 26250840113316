import React from 'react';


const SkeletonPost = () => {
  return (

    <>
    <div className="skeleton-post">
      <div className="skeleton-circle"></div>
      <div className="skeleton-content">
        <div className="skeleton-line"></div>
        <div className="skeleton-line short"></div>
        <div className="skeleton-line"></div>
      </div>
    </div>

<div className="skeleton-post">
<div className="skeleton-circle"></div>
<div className="skeleton-content">
  <div className="skeleton-line"></div>
  <div className="skeleton-line short"></div>
  <div className="skeleton-line"></div>
</div>
</div>

<div className="skeleton-post">
      <div className="skeleton-circle"></div>
      <div className="skeleton-content">
        <div className="skeleton-line"></div>
        <div className="skeleton-line short"></div>
        <div className="skeleton-line"></div>
      </div>
    </div>
    </>
  );
};

export default SkeletonPost;
